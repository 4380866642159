import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout/Layout"
import { graphql } from "gatsby"
import Video from "../components/Video/Video"

const VideoTemplate = ({ data }) => {
  const productData = data.allSitePage.edges[0].node.context

  return (
    <>
      <SEO title={productData.name} />

      <Layout url={"/" + productData.url}>
        <div className="video-template-wrapper">
          <h1>{productData.name}</h1>
          <Video name={productData.video} />
        </div>
      </Layout>
    </>
  )
}

export default VideoTemplate

export const query = graphql`
  query($path: String) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            name
            url
            video
          }
        }
      }
    }
  }
`
